@import 'style';

.logos {
	overflow: hidden;

	&-title {
		margin-top: 25 * $px;
	}
}

.grid {
	margin-top: 25 * $px;

	&-items {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		margin-top: -30px;
		margin-left: -60px;

		&-item {
			margin-top: 30px;
			padding-left: 60px;

			@include breakpoint('ipad-land') {
				width: 25%;
			}
		}
	}
}

.imgcontainer {
	position: relative;
	width: 100%;
	max-width: 180px;
}

.dialog {
	& div {
		border-radius: 0;

		@include breakpoint('ipad-land') {
			overflow-y: visible;
		}
	}

	&-content {
		overflow-y: hidden;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		padding-top: 24 * $px;

		& > :last-child {
			padding-bottom: 24 * $px;

			@include breakpoint('ipad-land') {
				padding-bottom: 36 * $px;
			}
		}

		&-imgcontainer {
			background-color: white;
			width: 100%;
			max-width: 180px;
			top: 0;
			left: 50%;

			@include breakpoint('ipad-land') {
				position: absolute;
				transform: translate(-50%, -50%);
				padding: 10 * $px;
			}
		}

		&-title {
			padding: 0 24 * $px;
			margin-top: 25 * $px;
			font-weight: 700;
		}

		&-link {
			display: flex;
			align-items: center;
			gap: 15 * $px;
			padding: 0 24 * $px;
			margin-top: 25 * $px;
		}

		&-text {
			overflow-y: auto;
			text-align: center;
			margin-top: 25 * $px;
			padding: 0 24 * $px;
		}
	}
}
