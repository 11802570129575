@import 'style';

.news {
	overflow: hidden;
	background-color: white;
	padding: 75 * $px 0;

	&-top {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-end;
		justify-content: space-between;
		margin-top: -25 * $px;
		margin-left: -25 * $px;
		margin-bottom: 50 * $px;

		&-title,
		&-nav {
			margin-top: 25 * $px;
			padding-left: 25 * $px;
		}
	}
}

.content {
	position: relative;

	&-items {
		@include gridMarginLeft(0, -1);

		&-item {
			min-width: 100%;
			@include gridPaddingLeft(0, 1);

			@include breakpoint('small') {
				width: calc(100% / 2);
				min-width: calc(100% / 2);
			}

			@include breakpoint('ipad-port') {
				width: calc(100% / 3);
				min-width: calc(100% / 3);
			}

			@include breakpoint('large') {
				width: calc(100% / 4);
				min-width: calc(100% / 4);
			}
		}
	}
}

.contentextra {
	display: block;
	position: relative;
	color: white;
	background-color: $dark-grey;
	width: 100%;
	aspect-ratio: 16/9;
	transform: translateY(0);
	transition: transform 0.25s ease-out;
	box-shadow: 0 15px 25px 0 rgba(0, 0, 0, 0.15);

	&:hover {
		@include breakpoint('desktop') {
			transform: translateY(-20 * $px);
		}
	}

	&-content {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		padding: 15 * $px;

		&-icon {
			display: block;
			font-size: 80 * $px;
		}

		&-icon + &-title {
			margin-top: 20 * $px;
		}

		&-title {
			text-align: center;
		}
	}
}
