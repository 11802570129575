@import 'style';

.readingroomtags {
	position: relative;
	z-index: 10;

	&-top {
		@include breakpoint('tablet') {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		&-left {
			margin-top: 10 * $px;
			@include breakpoint('tablet') {
				display: flex;
				align-items: center;
			}

			&-tags {
				margin-left: -8 * $px;
			}

			&-clear {
				color: rgba($text, 0.5);
				line-height: 1.5;
				display: inline-block;
				vertical-align: middle;
				border-bottom: 1px solid transparent;
				transition: all 0.25s ease-out;

				@include breakpoint('tablet') {
					flex: 0 0 auto;
					margin-left: 25 * $px;
				}

				&:hover {
					@include breakpoint('desktop') {
						color: $text;
						border-color: $text;
					}
				}
			}
		}
	}
}
