@import 'style';

.iconintro {
	padding-top: 60 * $px;
	border-top: 1px solid $background;

	&-row {
		gap: 30px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		align-items: center;

		@include breakpoint('tablet') {
			flex-wrap: nowrap;
			flex-direction: row;
		}
	}

	&-icon {
		margin: 0 auto;
		max-width: 170 * $px;

		@include breakpoint('tablet') {
			flex: 0 0 auto;
		}
	}

	&-description {
		font-size: 18 * $px;
		line-height: 1.5;

		@include breakpoint('tablet') {
			flex: 1 1 auto;
		}

		@include breakpoint('ipad-port') {
			font-size: 23 * $px;
		}
	}
}
