@import 'style';

$overlayColor: #3d3d3d;

.bg {
	overflow: hidden;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	&-video {
		display: none;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;

		& + .bg-overlay {
			@media (max-width: 1024px) {
				opacity: 1 !important;

				.mod-darkcolors & {
					background: white;
				}
			}
		}

		@include breakpoint('ipad-land') {
			display: block;
		}
	}

	&-overlay {
		background: $overlayColor;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		.mod-darkcolors & {
			background: #eee;
		}
	}

	&-overlay {
		.mod-cases & {
			opacity: 0.65 !important;
		}

		.mod-gradient & {
			opacity: 1 !important;
			background: linear-gradient(
				to bottom,
				rgba($overlayColor, 0) 0%,
				rgba($overlayColor, 0.65) 75%
			);
		}
	}
}
