@import 'style';

.iconoverview {
	&-space {
		height: 30 * $px;
		background-color: $background;
	}

	&-content {
		padding: 40 * $px 0;
	}

	&-more {
		background-color: $background;
		&-button {
			color: $blue;
			font-weight: bold;
			margin: 0 auto;
			max-width: 200 * $px;
			width: 100%;
			padding: 40 * $px 0;
			text-align: center;
			text-decoration: underline solid transparent;
			transition: text-decoration 0.3s ease;
			&:hover {
				text-decoration: underline solid currentColor;
				cursor: pointer;
			}
		}
	}
}

.project {
	padding: 28 * $px 0;

	&-row {
		gap: 30px;
		display: flex;
		flex-direction: column;

		@include breakpoint('tablet') {
			flex-direction: row;
		}
	}

	&-image {
		position: relative;

		@include breakpoint('tablet') {
			width: 50%;
		}

		@include breakpoint('ipad-land') {
			width: 25%;
		}
	}

	&-content {
		@include breakpoint('tablet') {
			width: 50%;
		}

		@include breakpoint('ipad-land') {
			width: 75%;
		}

		&-title {
			font-size: 25 * $px;
			font-weight: 700;
		}

		&-description {
			margin: 20 * $px 0;
		}

		&-link {
			color: #3f98bd;
			border-bottom: 1px solid transparent;
			transition: 0.3s ease-out;

			&:hover {
				border-bottom: 1px solid #3f98bd;
			}
		}
	}
}
