@import 'style';

$color: $blue;

.newsletter {
	overflow: hidden;
	background-color: $dark-grey;
	padding: 75 * $px 0;
	position: relative;

	&-content {
		color: white;
		font-weight: 700;
		margin: 0 auto;
		max-width: 600px;
		text-align: center;

		&-subtitle {
			color: #929396;
		}

		&-subtitle + &-title {
			margin-top: 10 * $px;
		}

		&-title {
			font-size: 26 * $px;
			line-height: 1.5;

			@include breakpoint('ipad-port') {
				font-size: 36 * $px;
			}
		}

		&-description {
			font-size: 20 * $px;
			line-height: 1.5;
			font-weight: 500;
			margin-top: 25 * $px;

			.theme_wysiwyg ul {
				list-style: none;
				display: inline-flex;
				flex-direction: column;
				align-items: center;

				li {
					display: inline-flex;
					padding-left: 40px;

					&:before {
						content: '';
						display: inline-block;
						vertical-align: middle;
						width: 30px;
						height: 20.17px;
						background: url('~assets/images/list-checkmark-blue.svg') no-repeat;
					}
				}

				li + li {
					margin-top: 10 * $px;
				}
			}
		}
	}
}

.form {
	position: relative;
	background-color: white;
	padding: 15 * $px;
	padding-left: 30 * $px;
	border-radius: 3px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 26 * $px;

	&-input {
		flex: 1 1 auto;
		color: #3b3b3a;
		margin-right: 15 * $px;
	}

	&-submit {
		cursor: pointer;
		color: white;
		line-height: 1.5;
		font-weight: 300;
		display: inline-block;
		vertical-align: top;
		padding: 8 * $px 12 * $px;
		background-color: $color;
		border-radius: 3 * $px;
		transition: all 0.25s ease-out;

		&:hover {
			background-color: lighten($color, 5%);
		}
	}
}
