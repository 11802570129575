@import 'style';

.readingroom {
	background-color: $background;
}

:global(.hero) + .readingroom {
	margin-top: -75 * $px;

	.readingroom_search {
		padding-top: 0px;
	}
}

.tabs {
	background-color: white;

	&-items {
		display: flex;
		flex-wrap: wrap;
		margin-top: -3px;
		margin-left: -3px;

		&-item {
			flex: 0 1 calc(50% - 3px);
			cursor: pointer;
			background: $brown-grey;
			display: inline-block;
			vertical-align: bottom;
			margin-top: 3px;
			margin-left: 3px;
			padding: 6px 24px;
			color: white;
			font-size: 16 * $px;
			font-weight: 400;
			text-align: center;
			transition: all 0.3s;
			@include breakpoint('tablet') {
				flex: 0 1 auto;
			}
			&.is-active {
				pointer-events: none;
				background: $dark-grey;
			}
			&:hover {
				background: $dark-grey;
			}
		}
	}
}
