@import 'style';

.centered {
	position: relative;
	padding: 75 * $px 0;

	&:not(.mod-background) {
		background: $gradient;
	}

	&.mod-article {
		&-content {
			h2 {
				font-size: 20 * $px;
				line-height: 1.5;
				font-weight: 600;

				&:before {
					display: none;
				}
			}
		}
	}

	&-content {
		color: white;
		font-size: 20 * $px;
		line-height: 1.5;
		text-align: center;
		margin: 0 auto;

		@include breakpoint('desktop') {
			max-width: 55%;
		}

		.mod-darkText & {
			color: $text;
		}

		h2:before {
			display: none;
		}

		ul {
			li {
				padding-left: 40px;
				margin-top: 10px;
				margin-bottom: 15px;

				&:before {
					content: '';
					width: 30px;
					height: 20.17px;
					position: relative;
					top: -3px;
					display: inline-block;
					vertical-align: middle;
					margin: 0 25px 0 -55px;
					background: url('~assets/images/list-checkmark-blue.svg') no-repeat;
				}

				.mod-icon & {
					border-top: 1px solid hsla(0, 0%, 90%, 0.3);
					padding: 5 * $px;
					&:before {
						content: none;
					}
				}
			}
		}

		a.btn,
		a.link {
			font-size: 18 * $px;
		}
	}
}
