@import 'style';

.footernav {
	margin: 45 * $px 0;

	&-row {
		margin-left: -30 * $px;
		margin-top: -30 * $px;

		@include breakpoint('tablet') {
			display: flex;
		}
	}
}

.categories {
	flex: 1 1 auto;
	padding-left: 30 * $px;
	margin-left: -60 * $px;

	&-item {
		display: inline-block;
		vertical-align: top;
		padding-left: 60 * $px;
		margin-top: 30 * $px;

		@include breakpoint('ipad-port') {
			width: calc(100% / 3);
		}

		&-title {
			padding-bottom: 10 * $px;
			border-bottom: 1px solid rgba($brown-grey, 0.5);
		}

		&-description {
			color: rgba($brown-grey, 0.5);
			font-size: 14 * $px;
			line-height: 1.5;
			margin-top: 5 * $px;
		}
	}
}

.other {
	padding-left: 30 * $px;
	margin-top: 30 * $px;

	@include breakpoint('tablet') {
		flex: 0 0 140 * $px;
	}
}

.links {
	margin-top: 10 * $px;

	&-item {
		& + & {
			margin-top: 15 * $px;
		}
	}
}

.link {
	color: #9e9e9e;
	line-height: 1.5;
	font-weight: 500;
	display: inline-block;
	vertical-align: top;
	transition: color 0.25s ease-out;

	&:hover {
		color: $dark-grey;
	}
}
