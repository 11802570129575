@import 'style';

.cookiemissing {
	width: 100%;
	height: 100%;
	padding: 30px;
	display: flex;
	text-align: center;
	align-items: center;
	justify-content: center;
	background: $gradient-diagonal;
	color: white;

	@include breakpoint('desktop') {
		padding: 60px;
	}

	&-content {
		&-button {
			margin-top: 30px;
		}
	}
}
