@import 'style';

.productspreview {
	background: linear-gradient(to bottom, $light-grey 50%, #fff 50%);
	padding: 85 * $px 0;

	&-button {
		display: block;
		text-align: center;
		margin-top: 35 * $px;
	}
}

.items,
.carousel {
	width: calc(100% + 36px);
	margin-left: -18px;

	&-item {
		min-width: 100%;
		padding: 0 18px;
		display: inline-block;
		vertical-align: bottom;

		@include breakpoint('tablet') {
			min-width: 50%;
		}

		@include breakpoint('ipad-port') {
			min-width: (100%/3);
		}

		@include breakpoint('desktop') {
			min-width: (100%/5);
		}

		&:nth-child(odd) {
			.item {
				@include breakpoint('desktop') {
					transform: scale(0.85);
				}
			}
		}
	}
}

.items {
	text-align: center;
}

.carousel {
	& > div > div {
		align-items: flex-end;
	}

	&-nav {
		display: block;
		text-align: center;
		margin-top: 25 * $px;
	}
}

.item {
	display: block;
	transform: scale(1);
	transform-origin: center bottom;
	transition: transform 0.25s ease-out;

	.image {
		margin: 0 auto;
	}
}
