@import 'style';

.hero {
	position: relative;
	background-color: white;
	padding: 80 * $px 0;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	color: white;

	&.mod-darkcolors {
		color: $dark-grey;
	}

	&.mod-image,
	&.mod-video {
		background-color: $dark-grey;
		padding: 75 * $px 0;

		@include breakpoint('ipad-port') {
			padding: 175 * $px 0 75 * $px 0;
		}

		@include breakpoint('ipad-land') {
			min-height: 560 * $px;
		}
	}

	&.mod-product {
		padding-top: 150 * $px;
		padding-bottom: 250 * $px;

		@include breakpoint('ipad-port') {
			padding-bottom: 150 * $px;
		}

		@include breakpoint('ipad-land') {
			padding-bottom: 75 * $px;
			min-height: calc(100vh - #{140 * $px});
		}
	}

	&.mod-small {
		min-height: 410px;
	}

	.mod-preview & {
		min-height: inherit;
	}

	&-content {
		width: 100%;

		&.mod-subsites {
			margin-top: 40 * $px;
		}
	}

	&-scroll {
		position: absolute;
		left: 0;
		bottom: 30 * $px;
		width: 100%;
		text-align: center;

		@include breakpoint('ipad-port') {
			bottom: 60 * $px;
		}
	}
}

.back {
	margin-top: 20 * $px;

	&-link {
		display: inline-block;
		align-items: center;
		transition: all 0.25s ease-out;

		&-text {
			display: inline-block;
			text-transform: uppercase;
			margin-left: 10 * $px;
		}

		&-circle {
			color: $blue;
			font-size: 12 * $px;
			width: 30px;
			height: 30px;
			border-radius: 100%;
			background-color: white;
			display: inline-flex;
			align-items: center;
			justify-content: center;

			.mod-darkcolors & {
				color: white;
				background-color: $blue;
			}
		}

		&:hover {
			transform: translateX(-5 * $px);
		}
	}
}

.subtitle {
	display: block;
}

.title {
	@include breakpoint('ipad-port') {
		@include gridMaxWidth(10, 9);
	}

	.mod-product & {
		@include breakpoint('ipad-land') {
			@include gridMaxWidth(8, 7);
		}

		@include breakpoint('desktop') {
			@include gridMaxWidth(7, 6);
		}
	}
}

.info {
	font-size: 20 * $px;
	line-height: 1.5;
	margin-top: 15 * $px;
	font-weight: 600;
}

.description {
	font-size: 20 * $px;
	line-height: 1.5;
	margin-top: 15 * $px;

	@include breakpoint('ipad-port') {
		@include gridMaxWidth(7, 6);
	}

	@include breakpoint('ipad-land') {
		@include gridMaxWidth(8, 7);
	}

	.mod-product & {
		@include breakpoint('desktop') {
			@include gridMaxWidth(7, 6);
		}
	}
}

.button {
	display: inline-block;
	vertical-align: top;
	margin-top: 25 * $px;
}

.buttons {
	margin-top: 10 * $px;
	margin-left: -15 * $px;

	.button {
		display: inline-block;
		vertical-align: top;
		margin-top: 15 * $px;
		padding-left: 15 * $px;
	}
}

.link {
	margin-top: 20 * $px;
}

.breadcrumbs {
	width: 100%;
	margin-bottom: 35 * $px;

	.mod-image &,
	.mod-video &,
	.mod-product &,
	.mod-producthero & {
		color: white;

		@include breakpoint('ipad-port') {
			position: absolute;
			top: 80 * $px;
			left: 0;
		}
	}

	.mod-darkcolors & {
		color: inherit;
	}

	&-icon,
	&-divider,
	&-link {
		display: inline-block;
		vertical-align: middle;
	}

	&-icon {
		font-size: 11 * $px;
		transition: color 0.25s ease-out;
		&:hover {
			color: $blue;
		}
	}

	&-divider {
		margin: 0 5 * $px;
	}

	&-link {
		display: inline;
		border-bottom: 1px solid transparent;
		transition: border 0.25s ease-out;

		&[href]:hover {
			border-color: $text;
			.mod-image &,
			.mod-video & {
				border-color: white;
			}
		}
	}
}

.subsiteinfo {
	width: 100%;

	.mod-image &,
	.mod-video & {
		@include breakpoint('ipad-land') {
			position: absolute;
			top: 80 * $px;
			left: 0;
		}
	}

	&-logo {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 40 * $px;

		&-image {
			flex-basis: auto;
			position: relative;
			height: 30px;
			transition: transform 0.25s ease-out;

			&:hover {
				transform: scale(1.05);
			}
		}
	}

	&-subPages {
		margin-top: -15 * $px;
		margin-left: -30 * $px;

		&-item {
			opacity: 0.6;
			font-weight: 500;
			font-size: 16 * $px;
			line-height: 1.5;
			display: inline-block;
			vertical-align: top;
			margin-top: 15 * $px;
			padding-left: 30 * $px;
			transition: all 0.25s ease-out;

			@include breakpoint('ipad-land') {
				font-size: 18 * $px;
			}

			&.mod-active {
				opacity: 1;
				pointer-events: none;
			}

			&:hover {
				opacity: 1;
			}
		}
	}
}

.product {
	width: 310px;
	position: absolute;
	left: 15 * $px;
	bottom: -45 * $px;

	@include breakpoint('ipad-port') {
		left: auto;
		right: 40 * $px;
	}

	@include breakpoint('ipad-land') {
		bottom: -65 * $px;
	}

	@include breakpoint('desktop') {
		width: 420px;
	}
}
