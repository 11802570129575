@import 'style';

.herohome {
	position: relative;
	background-color: white;
	padding: 80 * $px 0;
	display: flex;
	flex-wrap: wrap;
	align-items: center;

	&.mod-image {
		background-color: $dark-grey;
		padding: 150 * $px 0;

		@include breakpoint('ipad-land') {
			min-height: calc(100vh - #{140 * $px});
		}
	}

	.mod-preview & {
		min-height: inherit;
	}

	&-content {
		width: 100%;

		@include breakpoint('ipad-port') {
			@include gridMaxWidth(10, 9);
		}

		@include breakpoint('ipad-land') {
			@include gridMaxWidth(8, 7);
		}

		.mod-image & {
			color: white;
		}

		&-description {
			h1,
			h2,
			h3,
			h4,
			h5,
			p {
				font-size: 20 * $px;
				line-height: 1.5;
				font-weight: 300;

				@include breakpoint('ipad-port') {
					font-size: 32 * $px;
				}
			}

			h1,
			h2,
			h3,
			h4,
			h5,
			p {
				& + p {
					margin-top: 15 * $px;
				}
			}
		}
	}
}
