@import 'style';

.blocks2 {
	background-color: white;

	&-row {
		@include breakpoint('ipad-port') {
			display: flex;
		}
	}

	&-left,
	&-right {
		padding: 50 * $px 0;

		@include breakpoint('ipad-port') {
			display: inline-block;
			vertical-align: top;
			width: 50%;
			padding: 85 * $px 0;
		}

		@include breakpoint('ipad-land') {
			font-size: 18 * $px;
			line-height: (30/18);
		}
	}

	&-left {
		@include breakpoint('ipad-port') {
			@include gridPaddingRight(1, 0.5);
		}
	}

	&-right {
		@include breakpoint('ipad-port') {
			@include gridPaddingLeft(1, 0.5);
		}
	}

	&-left + &-right {
		@include breakpoint('ipad-port') {
			border-left: 1px solid $line;
		}
	}
}
