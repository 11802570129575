@import 'style';

.gallery {
	padding: 75 * $px 0;

	&-container {
		position: relative;
		width: 100%;
		max-width: 960 * $px;
		margin: 0 auto;
	}

	&-content {
		background-color: $dark-grey;
		position: relative;
		color: white;

		&-carousel {
			position: relative;
		}

		&-previous,
		&-next {
			display: none;

			@include breakpoint('ipad-land') {
				display: block;
				color: $dark-grey;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
			}
		}

		&-previous {
			@include breakpoint('ipad-land') {
				right: calc(100% + #{15 * $px});
			}
		}

		&-next {
			@include breakpoint('ipad-land') {
				left: calc(100% + #{15 * $px});
			}
		}
	}
}

.image {
	position: relative;
	min-width: 100%;
	height: 0;
	padding-bottom: calc(100% / (16 / 9));

	&-icons {
		position: absolute;
		right: 10 * $px;
		top: 10 * $px;

		&-item {
			background-color: rgba($dark-grey, 0.75);
			display: inline-flex;
			align-items: center;
			justify-content: center;
			vertical-align: top;
			width: 36 * $px;
			height: 36 * $px;
			border-radius: 3 * $px;
			transition: all 0.25s ease-out;

			&:hover {
				background-color: $blue;
			}

			&-icon {
				width: 20 * $px;
			}
		}
	}

	&-description {
		background-color: rgba($dark-grey, 0.75);
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		padding: 15 * $px;

		.theme_wysiwyg {
			font-size: 14 * $px;
			line-height: 1.5;
		}
	}
}

.video {
	position: relative;
	min-width: 100%;
	height: 0;
	padding-bottom: calc(100% / (16 / 9));

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100% !important;
		height: 100% !important;
	}
}

.nav {
	position: relative;

	&-items {
		display: flex;
		flex-wrap: wrap;
		margin-left: -5 * $px;
		margin-top: -5 * $px;
		margin-top: 7px;
	}

	&-item {
		opacity: 0.5;
		width: calc(100% / 3);
		transition: opacity 0.25s ease-out;
		border: 3px solid white;
		background-color: $dark-grey;

		@include breakpoint('ipad-port') {
			width: calc(100% / 6);
		}

		&:hover {
			opacity: 0.75;
		}

		&.is-active {
			opacity: 1;
			border-color: $blue;
		}
	}
}
