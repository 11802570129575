@import 'style';

.headermobilenav {
	background-color: $dark-grey;
	overflow-y: auto;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 85 * $px 0;

	&-close {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 60 * $px;
		height: 60 * $px;
		border-radius: 100%;
		background-color: white;
		box-shadow: 0 2px 5px 0 rgba(black, 0.5);
		position: fixed;
		bottom: 50 * $px;
		left: 50%;
		transform: translateX(-50%);

		&-icon {
			font-size: 20 * $px;
		}
	}
}

.headermobilenav_links {
	&-item {
		&-link {
			padding: 0 30 * $px;

			&-text,
			&-icon {
				display: inline-block;
				vertical-align: middle;
			}

			&-text {
				color: white;
				font-size: 24 * $px;
				line-height: 1.5;
			}

			&-icon {
				color: #979797;
				font-size: 12 * $px;
				margin-top: 3 * $px;
				margin-left: 15 * $px;
			}
		}

		&-divider {
			width: 100%;
			height: 1px;
			background-color: rgba($light-grey, 0.25);
			margin: 25 * $px 0;
		}

		& + & {
			margin-top: 25 * $px;
		}
	}
}

.headermobilenav_submenu {
	&-back {
		display: inline-block;
		vertical-align: top;
		padding: 0 30 * $px;
		margin-bottom: 25 * $px;
		position: absolute;
		top: 25 * $px;
		left: 0;

		&-text,
		&-icon {
			display: inline-block;
			vertical-align: middle;
		}

		&-icon + &-text {
			margin-left: 15 * $px;
		}

		&-text {
			color: #979797;
		}

		&-icon {
			color: #979797;
			font-size: 12 * $px;
			margin-top: 3 * $px;
		}
	}

	&-link {
		padding: 0 30 * $px;

		&-text,
		&-icon {
			display: inline-block;
			vertical-align: middle;
		}

		&-text {
			color: white;
			font-size: 24 * $px;
			line-height: 1.5;
		}

		&-icon {
			color: #979797;
			font-size: 12 * $px;
			margin-top: 3 * $px;
			margin-left: 15 * $px;
		}
	}

	&-divider {
		width: 100%;
		height: 1px;
		background-color: rgba($light-grey, 0.25);
		margin: 25 * $px 0;
	}

	&-items {
		padding: 0 30 * $px;
	}

	&-item {
		color: white;

		&-link {
			display: flex;
			align-items: center;
			justify-content: space-between;

			&-text,
			&-icon {
				display: inline-block;
				vertical-align: middle;
			}

			&-icon {
				font-size: 14 * $px;
			}
		}

		&-description {
			color: $brown-grey;
			margin-top: 10 * $px;
			padding-top: 10 * $px;
			border-top: 1px solid rgba($light-grey, 0.75);
		}

		& + & {
			margin-top: 50 * $px;
		}
	}

	& + & {
		margin-top: 25 * $px;
	}
}
