@import 'style';

.calendar {
	&-block {
		background-color: $dark-grey;
		margin-top: 30 * $px;
		padding: 15 * $px 0;
		color: white;
	}
}

.contentview {
	@include breakpoint('ipad-port') {
		display: flex;
	}

	&-list {
		overflow: hidden;
		background-color: $blue;
		padding: 50 * $px 25 * $px;
		padding-bottom: 25 * $px;
		color: white;

		@include breakpoint('ipad-port') {
			flex: 0 0 auto;
			width: 100%;
			max-width: 375px;
		}

		@include breakpoint('ipad-land') {
			padding: 50 * $px;
		}
	}

	&-content {
		flex: 1 1 auto;
		position: relative;
		background-color: white;
		padding: 50 * $px 25 * $px;

		@include breakpoint('ipad-land') {
			padding: 50 * $px;
		}

		@include breakpoint('desktop') {
			padding: 75 * $px;
			padding-top: 50 * $px;
		}

		&-overlay {
			background-color: rgba($dark-grey, 0.65);
			position: absolute;
			inset: 0;
		}

		&-text {
			position: relative;
			z-index: 1;

			&.mod-image {
				color: white;
			}
		}
	}
}

.events {
	&-item {
		position: relative;
		display: block;
		padding: 15 * $px 30 * $px;
		transition: background 0.25s ease-out;

		@include breakpoint('tablet') {
			display: inline-block;
			vertical-align: top;
			width: calc(100% / 2);
			margin: 15 * $px 0;
		}

		@include breakpoint('ipad-land') {
			width: calc(100% / 3);
			padding: 15 * $px 45 * $px;
		}

		@include breakpoint('desktop') {
			&:hover {
				background-color: lighten($dark-grey, 5%);
			}
		}

		&.mod-contentview {
			width: 100%;
			margin: 0;
			padding: 25 * $px 0;

			&:before {
				content: '';
				background-color: lighten($blue, 5%);
				position: absolute;
				top: 0;
				left: -50px;
				height: 100%;
				width: calc(100% + 100px);
				opacity: 0;
				transition: opacity 0.25s ease-out;
			}

			@include breakpoint('desktop') {
				&:hover {
					background: none;

					&:before {
						opacity: 1;
					}
				}
			}
		}

		&-wrapper {
			position: relative;
		}

		&-date,
		&-date-end {
			color: $blue;
			display: inline-block;
		}

		&.mod-contentview &-date,
		&.mod-contentview &-date-end {
			color: rgba(white, 0.8);
		}

		&-date-end {
			margin-left: 3 * $px;
		}

		&-location {
			display: flex;
			align-items: center;
			margin-top: 5 * $px;
			color: rgba(white, 0.8);
			line-height: 1.25;

			&-icon {
				margin-right: 8px;
			}
		}

		& + & {
			border-left: 1px solid rgba(#eee, 0.2);

			&.mod-contentview {
				border-left: none;
				border-top: 1px solid rgba(#eee, 0.2);
			}
		}
	}
}
