@import 'style';

.agenda {
	&-more {
		display: block;
		padding: 60px 0;
		text-align: center;
		background-color: $background;

		&-button {
			color: $blue;
			font-weight: 700;
			text-transform: uppercase;
			text-decoration: underline solid transparent;
			transition: text-decoration 0.3s ease;

			&:hover {
				cursor: pointer;
				text-decoration: underline solid $blue;
			}
		}
	}
}

.empty {
	padding: 55 * $px 0;
	margin-top: 25 * $px;

	&-title + &-text {
		margin-top: 5 * $px;
	}
}

.hero {
	padding: 75 * $px 0;
}

.events {
	&-space {
		height: 30 * $px;
		background-color: $background;
	}

	&-event {
		display: block;
		padding: 75 * $px 0;
		background-color: $light-grey;
		transition: background 0.25s ease-out;

		&:hover {
			background-color: white;
		}

		&-date,
		&-date-end {
			color: $brown-grey;
			font-weight: 500;
			display: inline-block;
			vertical-align: top;
		}

		&-date-end {
			margin-left: 3 * $px;
		}

		&-description {
			margin-top: 20 * $px;

			@include breakpoint('ipad-land') {
				font-size: 18 * $px;
				line-height: (30/18);
			}
		}
	}
}
