@import 'style';

.carouselcontrols {
	display: inline-block;
	vertical-align: top;
	line-height: 1.5;
}

.previous,
.next {
	font-size: 20 * $px;
	width: 44px;
	height: 44px;
	display: inline-flex;
	vertical-align: top;
	align-items: center;
	justify-content: center;
	transition: opacity 0.25s ease-out;

	&.is-disabled {
		pointer-events: none;
		opacity: 0.25;
	}
}

.previous + .next {
	margin-left: 25 * $px;
}
