@import 'style';

.inpagenav {
	background-color: white;
	padding: 0 25 * $px;
	position: sticky;
	top: 0;
	z-index: 200;
	border-bottom: 1px solid #eee;
	transition: opacity 0.3s ease-out;

	@include breakpoint('ipad-land') {
		padding: 0 50 * $px;
	}

	&.is-dark {
		background-color: $dark-grey;
		color: white;
	}

	&-fixed {
		position: relative;
	}

	&-row {
		line-height: 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&-hamburger {
		width: 44px;
		height: 44px;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		margin-left: -12 * $px;

		@include breakpoint('ipad-land') {
			display: none;
		}

		&-bar {
			display: block;
			height: 2px;
			width: 16 * $px;
			background-color: $dark-grey;

			.is-dark & {
				background-color: white;
			}

			& + & {
				margin-top: 5px;
			}
		}
	}

	&-button {
		flex: 0 0 auto;
		padding: 14 * $px 0;

		@include breakpoint('ipad-land') {
			margin-left: 25 * $px;
		}
	}
}

.items {
	display: none;
	margin-left: -15 * $px;
	padding-bottom: 15 * $px;

	@include breakpoint('tablet') {
		margin-left: -25 * $px;
	}

	@include breakpoint('ipad-land') {
		display: block;
		margin-left: 0;
		padding-bottom: 0;
	}

	&.mod-mobile {
		display: none;
	}

	&.is-open {
		display: block;

		@include breakpoint('ipad-land') {
			display: none;
		}
	}

	&-item {
		display: block;
		line-height: 1em;

		@include breakpoint('ipad-land') {
			display: inline-block;
			vertical-align: middle;
		}

		&-link {
			position: relative;
			padding: 10 * $px 15 * $px;
			display: inline-block;
			vertical-align: bottom;

			@include breakpoint('tablet') {
				padding: 10 * $px 25 * $px;
			}

			@include breakpoint('desktop') {
				padding: 28 * $px 35 * $px;
			}

			&:after {
				content: '';
				background-color: $blue;
				width: 100%;
				height: 4 * $px;
				position: absolute;
				left: 0;
				bottom: 0;
				transform: scaleX(0);
				transform-origin: right center;
				transition: transform 0.25s ease-out;
			}

			&:hover {
				@include breakpoint('desktop') {
					&:after {
						transform: scaleX(1);
						transform-origin: left center;
					}
				}
			}
		}
	}
}
