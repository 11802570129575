@import 'style';

.loader {
	&-circle {
		display: inline-block;
		vertical-align: top;
		width: 37.5 * $px;
		height: 37.5 * $px;
		border: 3px solid rgba($blue, 0.25);
		border-top-color: $blue;
		border-radius: 50%;
		animation: spin 1s linear infinite;
	}
}

@keyframes spin {
	to {
		transform: rotate(360deg);
	}
}
