@import 'style';

.alert {
	background: $gradient;
	display: block;
	padding: 15 * $px;
	color: white;
	line-height: 1.5;
	text-align: center;

	@include breakpoint('tablet') {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&-link {
		margin-left: 10px;
		text-decoration: underline;
	}
}
