@import 'style';

.headernav {
	display: none;

	@include breakpoint('ipad-land') {
		height: 100%;
		display: inline-block;
		vertical-align: middle;
		margin-left: 30 * $px;
		font-size: 0.9375em;
	}

	@include breakpoint('desktop-large') {
		font-size: 1em;
	}

	&.mod-subsite {
		@include breakpoint('ipad-land') {
			display: none;
		}
		@include breakpoint('desktop') {
			display: inline-block;
		}
	}

	&-item {
		height: 100%;
		display: inline-block;
		vertical-align: top;

		&:hover,
		&:focus-within,
		&.is-active {
			.headernav-item-link {
				background-color: white;

				&:after {
					transform: scaleX(1);
					transform-origin: left center;
				}
			}
		}

		&-link {
			height: 100%;
			position: relative;
			display: flex;
			align-items: center;
			padding: 12 * $px;
			transition: all 0.25s ease-out;

			&-text,
			&-icon {
				display: inline-block;
				vertical-align: middle;
			}

			&-text + &-icon {
				margin-left: 10px;
			}

			&-icon {
				color: #979797;
				font-size: 5 * $px;
			}

			&:after {
				content: '';
				background-color: $blue;
				width: 100%;
				height: 4 * $px;
				position: absolute;
				left: 0;
				bottom: 0;
				transform: scaleX(0);
				transform-origin: right center;
				transition: transform 0.25s ease-out;
			}
		}
	}
}

.dropdown {
	pointer-events: none;
	position: absolute;
	left: 0;
	top: 100%;
	width: 100%;
	z-index: 1;
	background-color: $background;
	padding: 30 * $px 0;
	border-top: 1px solid rgba($brown-grey, 0.5);
	transform: translateY(-100%);
	opacity: 0;
	box-shadow: 0 0 10px 0 rgba($dark-grey, 0.25);
	transition: opacity 0.2s ease-out, transform 0.2s ease-out;

	&.is-open {
		transform: none;
		opacity: 1;
		pointer-events: all;
	}

	&-items {
		gap: 30px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;

		@include breakpoint('ipad-land') {
			flex-direction: row;
		}

		&-item {
			@include breakpoint('ipad-land') {
				width: calc(100% / 3 - 30px);
			}
		}
	}

	&-item {
		&-link {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 10 * $px 8 * $px;
			border-bottom: 1px solid rgba($brown-grey, 0.5);
			transition: all 0.25s ease-out;

			&-text {
				position: relative;
			}

			&-icon {
				font-size: 12 * $px;
			}

			&:after {
				content: '';
				background-color: $blue;
				width: 100%;
				height: 4 * $px;
				position: absolute;
				left: 0;
				bottom: -1px;
				transform: scaleX(0);
				transform-origin: right center;
				transition: transform 0.25s ease-out;
			}

			&:hover,
			&:focus-visible {
				background-color: white;

				&:after {
					transform: scaleX(1);
					transform-origin: left center;
				}
			}
		}

		&-description {
			color: $brown-grey;
			font-size: 14 * $px;
			margin-top: 15 * $px;
		}
	}
}
