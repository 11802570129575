@import 'style';

.subjects {
	font-size: 16 * $px;
	margin-top: 20 * $px;

	&-title {
		position: relative;
	}

	&-items {
		gap: 50px 30px;
		display: flex;
		flex-direction: column;

		@include breakpoint('tablet') {
			flex-direction: row;
		}

		&-item {
			@include breakpoint('tablet') {
				width: 50%;
			}

			@include breakpoint('ipad-port') {
				width: calc(100% / 3);
			}
		}
	}
}

.column {
	position: relative;
}

.title {
	font-weight: 600;

	&-link {
		display: inline-block;
		border-bottom: 1px solid transparent;
		transition: border 0.25s ease-out;
		cursor: pointer;

		&:hover {
			border-color: $text;
		}

		&.mod-inactive {
			color: rgba($text, 0.5);
			pointer-events: none;
		}

		&.mod-grey {
			color: rgba($text, 0.5);

			&:hover {
				border-color: rgba($text, 0.5);
			}
		}
	}
}

.tag {
	margin-top: 5 * $px;

	&-link {
		display: inline-block;
		border-bottom: 1px solid transparent;
		transition: border 0.25s ease-out;
		cursor: pointer;

		&:hover {
			border-color: $text;
		}

		&.mod-inactive {
			color: rgba($text, 0.5);
			pointer-events: none;
		}

		&.mod-grey {
			color: rgba($text, 0.5);

			&:hover {
				border-color: rgba($text, 0.5);
			}
		}
	}
}
