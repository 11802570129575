@import 'style';

.contact {
	background: $gradient;
	padding: 45 * $px 0;
	color: white;

	&-row {
		gap: 30px 50px;
		display: flex;
		flex-direction: column;

		@include breakpoint('ipad-land') {
			flex-direction: row;
			align-items: center;
		}

		&-col {
			@include breakpoint('ipad-land') {
				&:first-child {
					width: calc(100% / 1.5);
				}

				&:last-child {
					width: calc(100% / 3);
				}
			}
		}
	}

	&-content {
		gap: 30px;
		display: flex;
		flex-direction: column;

		@include breakpoint('tablet') {
			flex-direction: row;
			align-items: center;
		}

		&-image {
			width: calc(100% / 3);
		}

		&-text {
			width: calc(100% / 1.5);

			h2 {
				&:before {
					display: none;
				}

				& + p {
					margin-top: 10 * $px;
				}
			}

			a {
				color: white;

				&:hover {
					color: white;
					border-color: currentColor;
				}
			}
		}
	}

	&-cta {
		display: flex;
		align-items: center;

		&-text {
			line-height: 1.5;
			text-transform: uppercase;
			margin-right: 10 * $px;
			border-bottom: 1px solid transparent;
			transition: border 0.25s ease-out;
		}

		&-circle {
			color: $blue;
			width: 40 * $px;
			height: 40 * $px;
			border-radius: 100%;
			background-color: white;
			display: flex;
			align-items: center;
			justify-content: center;
			transition: all 0.25s ease-out;

			&-icon {
				font-size: 12 * $px;
			}
		}

		&:hover .contact-cta {
			@include breakpoint('desktop') {
				&-text {
					border-color: white;
				}
				&-circle {
					transform: translateX(10px);
				}
			}
		}
	}
}
