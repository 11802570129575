@import 'style';

.carouselnav {
	line-height: 0;

	&-item {
		width: 30 * $px;
		height: 4 * $px;
		display: inline-block;
		vertical-align: top;
		background-color: #d7d7d7;
		transition: background 0.25s ease-out;

		&.is-active {
			background-color: $blue;
		}

		& + & {
			margin-left: 5 * $px;
		}
	}
}
