@import 'style';

.magazine {
	color: white;
	padding: 75 * $px 0;
	background-color: $dark-grey;

	> .highlighted,
	> .popular,
	> .items {
		&:not(:first-child) {
			margin-top: 55 * $px;
		}
	}
}

.items {
	position: relative;

	&-flex {
		margin-top: -65px;

		@include breakpoint('tablet') {
			display: flex;
			flex-wrap: wrap;
			margin-left: -30px;
		}

		&-item {
			margin-top: 65px;

			@include breakpoint('tablet') {
				width: 50%;
				padding-left: 30px;
			}

			@include breakpoint('ipad-port') {
				width: calc(100% / 3);
			}
		}
	}
}

.loader {
	color: white;
	padding: 75 * $px 0;
	background-color: $dark-grey;
	display: flex;
	justify-content: center;
	align-items: center;
}

.textual {
	display: block;
	padding: 60 * $px 0;
	margin-top: 30 * $px;
	background-color: white;

	&:hover {
		.textual-title {
			border-color: $dark-grey;
		}
	}

	&-space {
		height: 30 * $px;
		background-color: $background;
	}

	&-date {
		color: $brown-grey;
		font-size: 20 * $px;
		font-weight: 500;
		margin-bottom: 10 * $px;
	}

	&-title {
		display: inline-block;
		vertical-align: top;
		border-bottom: 1px solid transparent;
		transition: border 0.3s ease-out;
	}

	&-description {
		font-size: 18 * $px;
		margin-top: 30 * $px;
	}

	&-title,
	&-description {
		strong {
			background-color: rgba($blue, 0.1);
			color: $blue;
		}
	}

	&-container {
		padding: 30 * $px 0;
		margin-top: 30 * $px;
		background-color: white;
	}
}
