@import 'style';

.textblock {
	&-content {
		@include breakpoint('ipad-land') {
			font-size: 18 * $px;
			line-height: (30/18);
		}

		@include breakpoint('desktop') {
			@include gridMaxWidth(8, 7);
		}

		@include breakpoint('large') {
			@include gridMaxWidth(10, 9);
		}

		:global(.mod-fullwidth) & {
			max-width: 100%;
		}

		div,
		p,
		span {
			color: inherit !important;
			font: inherit !important;
			line-height: inherit !important;
			text-align: initial !important;
		}
	}

	&.mod-job {
		h1:first-child {
			display: none;
		}
		p:empty {
			display: none;
		}
		.theme_wysiwyg {
			div,
			span,
			b,
			i,
			p,
			#requisition_EN {
				font-style: inherit !important;
				font-family: inherit !important;
				font-weight: inherit !important;
				font-size: inherit !important;
				color: inherit !important;
			}
		}
	}
}

//MAILCHIMP EMBED
#mc_embed_signup {
	:global(.mc-field-group) {
		select {
			width: 100% !important;
			display: block !important;
			text-indent: 2%;
			background-color: white;
			padding: 8px 0 !important;
			border: 1px solid #abb0b2;
			border-radius: 3px;
			margin-bottom: 0 !important;
			background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 23.0.3, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Laag_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 10 5' style='enable-background:new 0 0 10 5;' xml:space='preserve'%3E%3Cpolygon points='5,5 0,0 10,0 '/%3E%3C/svg%3E");
			background-repeat: no-repeat;
			background-size: 0.65em auto;
			background-position: right 0.7em top 50%;
		}

		&.input-group {
			input {
				position: absolute;
				opacity: 0;

				& + label {
					position: relative;
					cursor: pointer;
					padding: 0;

					&:before {
						content: '';
						margin-right: 15px;
						display: inline-block;
						vertical-align: text-top;
						width: 15px;
						height: 15px;
						background-color: white;
						border: 1px solid #abb0b2;
						border-radius: 3px;
					}

					&:after {
						content: '✓';
						color: $blue;
						position: absolute;
						left: 3px;
						top: -3px;
						opacity: 0;
						transform: scale(0);
						transition: all 0.3s ease-out;
					}
				}

				&:checked + label:after {
					opacity: 1;
					transform: scale(1);
				}
			}
		}
	}

	:global(.button) {
		background-color: $blue !important;
		color: white !important;
		font-size: 16 * $px !important;
		line-height: 1.5 !important;
		font-weight: 500 !important;
		height: auto !important;
		padding: 7.5px 20px !important;

		&:hover {
			background-color: lighten($blue, 5%) !important;
		}
	}
}
