@import 'style';

.info {
	background-color: #fff;
	border-top: 1px solid #f0f0f0 !important;
	padding: 50px 0;
	&-items,
	&-text,
	&-empty {
		font-size: 28 * $px;
		line-height: 1.5;
	}
	&-items {
		margin: 10px 0 0 60px;
	}
	&-item {
		margin-top: 5px;
		padding-left: 15px;
	}
}

.options {
	margin-top: 50px;
	&-btn {
		margin-right: 20px;
	}
}
