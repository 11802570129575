@import 'style';

.popular {
	color: $dark-grey;
	margin-top: 5 * $px;
	padding-left: 20 * $px;

	&-topics {
		display: inline-flex;
		padding-left: 8 * $px;

		&-item {
			cursor: pointer;
			text-decoration: underline;
			font-weight: bolder;

			&:not(:last-child) {
				margin-right: 5 * $px;
				&:after {
					content: ',';
				}
			}
		}
	}
}

.toggler {
	line-height: 1.5;
	display: flex;
	height: 100%;
	justify-content: flex-end;
	margin-left: auto;

	@include breakpoint('desktop') {
		// margin: 15 * $px 0 auto auto;
	}

	&-toggle {
		&-label,
		&-icon {
			display: inline-block;
			vertical-align: middle;
		}

		&-label {
			margin-right: 10 * $px;
			border-bottom: 1px solid transparent;
			transition: border 0.25s ease-out;
			display: flex;
			align-items: center;
		}

		&-icon {
			// color: ;
			font-size: 6 * $px;
			margin-left: 15 * $px;
			transition: transform 0.25s ease-out;
		}

		&:hover .toggler-toggle {
			@include breakpoint('desktop') {
				&-label {
					border-color: currentColor;
				}
			}
		}

		&.is-open {
			.toggler-toggle {
				&-icon {
					transform: rotate(-180deg);
				}
			}
		}
	}
}

.input {
	position: relative;

	&-container {
		position: relative;

		&-input {
			width: 100%;
			padding: 17 * $px 59 * $px 17 * $px 20 * $px;
			border-radius: 4 * $px;
			background: white;
			border: 1px solid rgba($brown-grey, 0.5);
			transition: border 0.3s ease-out;

			&:focus-visible {
				outline: none;
				border-color: $brown-grey;
			}
		}

		&-icon {
			cursor: pointer;
			font-size: 18 * $px;
			position: absolute;
			right: 20 * $px;
			top: 50%;
			transform: translateY(-50%);
		}
	}

	&-dropdown {
		display: none;
		position: absolute;
		z-index: 1;
		top: 100%;
		left: 0;
		width: 100%;
		overflow: hidden;
		border-radius: 4 * $px;

		&-item {
			&-link {
				display: block;
				background-color: #f9f9f9;
				color: #8b8b8b;
				padding: 10 * $px 20 * $px;
				transition: all 0.25s ease-out;

				&:hover {
					background-color: #eeeeee;
				}

				b {
					color: $text;
				}
			}
		}
	}
}

.more {
	position: relative;
}
