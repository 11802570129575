@import "style";

.empty {
  padding: 55 * $px 0;
  margin-top: 25 * $px;

  &-title + &-text {
    margin-top: 5 * $px;
  }
}

.loader {
  padding: 25 * $px 0;
  text-align: center;
}

.more {
  color: $blue;
  font-weight: bold;
  margin: 0 auto;
  max-width: 200 * $px;
  width: 100%;
  padding: 60 * $px 0;
  text-align: center;

  &-button {
    text-decoration: underline solid transparent;
    transition: text-decoration 0.3s ease;
    &:hover {
      text-decoration: underline solid currentColor;
      cursor: pointer;
    }
  }
}
