@import 'style';

.faq {
	background-color: white;
	padding: 20 * $px 0;
	&:last-child {
		padding-bottom: 85 * $px;
	}

	&-title + &-items {
		margin-top: 30px;
	}
}

.item {
	&-question,
	&-answer {
		.theme_wysiwyg p {
			font-size: 18 * $px;
			line-height: (30/18);
		}
	}
	&-answer {
		margin-top: 10px;
	}

	&-question {
		position: relative;
		display: inline-flex;
		align-items: center;

		&-text {
			max-width: 550 * $px;

			.theme_wysiwyg p {
				font-weight: 600;
			}
		}

		&-icon {
			font-size: 10 * $px;
			margin-left: 20 * $px;

			.icon {
				transition: transform 0.25s ease-out;
			}

			&.is-open .icon {
				transform: rotate(90deg);
			}
		}
	}

	&-answer {
		max-width: 550 * $px;
	}

	& + & {
		margin-top: 35 * $px;
	}
}

.faq + .faq {
	border-top: 1px solid #eee;
}
