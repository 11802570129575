@import 'style';

.highlighted {
	position: relative;
	margin: 50 * $px 0;
	padding-top: 30 * $px;
	padding-bottom: 50 * $px;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(#d8d8d8, 0.3);

		@include breakpoint('ipad-land') {
			left: 4%;
			width: calc(100% - 8%);
		}
	}

	&.mod-blue {
		&:before {
			border-top: 5px solid $blue;
		}
	}

	&-title {
		margin-bottom: 30 * $px;
	}

	&-first {
		&-row {
			margin-top: -30px;

			@include breakpoint('tablet') {
				display: flex;
				flex-wrap: wrap;
				margin-left: -30px;
			}

			&-media,
			&-content {
				margin-top: 30px;

				@include breakpoint('tablet') {
					width: 50%;
					padding-left: 30px;
				}
			}

			&-media {
				@include breakpoint('ipad-port') {
					width: calc(100% / 1.5);
				}
			}

			&-content {
				@include breakpoint('ipad-port') {
					width: calc(100% / 3);
				}
			}
		}
	}

	&-items {
		margin-top: -65px;

		@include breakpoint('tablet') {
			display: flex;
			flex-wrap: wrap;
			margin-left: -30px;
		}

		&-item {
			margin-top: 65px;

			@include breakpoint('tablet') {
				width: 50%;
				padding-left: 30px;
			}

			@include breakpoint('ipad-port') {
				width: calc(100% / 3);
			}
		}
	}

	&-first + &-items {
		margin-top: 0 * $px;
	}
}

.image {
	overflow: hidden;
	position: relative;
	display: block;
	aspect-ratio: 16/9;
	background: $gradient-diagonal;
	box-shadow: 0 15px 25px 0 rgba(0, 0, 0, 0.15);

	&-bg {
		transform: scale(1) translateZ(0);
		backface-visibility: hidden;
		transition: transform 0.25s ease-out;
	}

	&-label {
		font-size: 12 * $px;
		line-height: 1.5;
		font-weight: 500;
		background-color: rgba($dark-grey, 0.5);
		border-radius: 50 * $px;
		padding: 5 * $px 15 * $px;
		position: absolute;
		left: 10 * $px;
		top: 10 * $px;
	}

	&:hover .image {
		&-bg {
			transform: scale(1.025) translateZ(0);
		}
	}

	&-volume {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		font-size: 5em;

		&-container {
			pointer-events: none;
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
			background: linear-gradient(
				to bottom,
				rgba($dark-grey, 0) 0%,
				rgba($dark-grey, 0.5) 100%
			);
		}
	}
}

.media {
	position: relative;
	display: block;
	aspect-ratio: 16/9;
	box-shadow: 0 15px 25px 0 rgba(0, 0, 0, 0.15);

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100% !important;
		height: 100% !important;
	}
}

.content {
	padding: 20 * $px 0;

	&-tags {
		margin-bottom: 15 * $px;
	}

	&-type {
		font-size: 14 * $px;
		line-height: 1.5;
		font-weight: 500;
	}

	&-title {
		@include m-lineclamp(5, 36 * $px);
	}

	&-date {
		font-size: 14 * $px;
		line-height: 1.5;
		margin-top: 15 * $px;
	}

	&-text {
		font-size: 16 * $px;
		line-height: (28/16);
		@include m-lineclamp(5, 28 * $px);
		margin-top: 15 * $px;
	}
}
