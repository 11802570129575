.icon {
	height: 1em;
	line-height: 1;
	display: block;

	&.mod-inline {
		display: inline-block;
	}

	> svg {
		width: auto;
		height: 100% !important;
	}

	&.mod-maxwidth {
		> svg {
			width: 100% !important;
		}
	}

	&:not(.mod-nofill) {
		svg,
		path:not(.raw) {
			fill: currentColor !important;
		}
	}
}
