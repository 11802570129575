@import 'style';

.imageblock {
	&-title {
		margin-bottom: 40 * $px;
	}

	&-image {
		.image {
			margin: 0 auto;
		}
	}

	&-content {
		margin: 0 auto;
		max-width: 960 * $px;

		&.mod {
			&-small {
				max-width: 320 * $px;
			}

			&-medium {
				max-width: 640 * $px;
			}

			&-fullwidth {
				max-width: 100%;
			}

			&-leaflet {
				margin: 0;
				max-width: 285 * $px;
			}
		}
	}

	&-caption {
		color: rgba($text, 0.5);
		font-style: italic;
		line-height: 1.5;
		padding-top: 10 * $px;

		@include breakpoint('ipad-port') {
			max-width: calc(100% / (710 / 560));
		}
	}
}
