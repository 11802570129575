@import 'style';

.links {
	padding: 80 * $px 0;

	&-title {
		margin-bottom: 25 * $px;
	}

	&-text {
		@include breakpoint('ipad-port') {
			@include gridMaxWidth(8, 7);
		}
	}

	&-items {
		overflow: hidden;
		margin-top: 25 * $px;

		@include breakpoint('tablet') {
			display: flex;
			flex-wrap: wrap;
			margin-left: -50px;
		}

		&-item {
			margin-top: -1px;

			@include breakpoint('tablet') {
				display: inline-block;
				vertical-align: top;
				width: calc(100% / 2);
				padding-left: 50px;
			}

			&-link {
				padding: 20 * $px 0;
				border-top: 1px solid $line;

				&-label {
					border-bottom: 1px solid transparent;
					transition: border 0.25s ease-out;

					@include breakpoint('ipad-land') {
						font-size: 18 * $px;
						line-height: (30/18);
					}

					&:hover,
					&:focus-visible {
						border-color: $dark-grey;
					}
				}
			}
		}
	}

	&-link {
		margin-top: 35 * $px;
	}
}
