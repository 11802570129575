@import 'style';

.readingroomsearch {
	background-color: white;
	padding: 75 * $px 0;

	&-title {
		margin-bottom: 30 * $px;
	}

	&-flex {
		gap: 30px 50px;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: flex-end;

		@include breakpoint('ipad-port') {
			justify-content: space-between;
		}

		&-input {
			flex: 1 1 100%;

			@include breakpoint('ipad-port') {
				flex-basis: auto;
				max-width: 768px;
			}
		}

		&-toggle {
			flex: 0 0 auto;
		}
	}
}
