@import 'style';

.downloads {
	background-color: $dark-grey;
	padding: 75 * $px 0;
	color: white;

	&.mod-light {
		background: transparent;
		padding: 0;
		color: $dark-grey;
	}

	&-title + &-content {
		margin-top: 40 * $px;
	}

	&-content {
		overflow: hidden;

		&-row {
			gap: 0 50px;
			display: flex;
			flex-wrap: wrap;
			flex-direction: column;

			@include breakpoint('ipad-port') {
				flex-direction: row;
			}

			&-col {
				@include breakpoint('ipad-port') {
					width: calc(50% - 25px);
				}
			}
		}
	}
}

.item {
	padding: 15 * $px 0;
	margin-top: -1px;
	border-top: 1 * $px solid $line;

	&-link {
		&-icon,
		&-text {
			display: inline-block;
			vertical-align: middle;
		}

		&-icon {
			width: 30px;
		}

		&-text {
			border-bottom: 1px solid transparent;
			transition: border 0.25s ease-out;
		}

		&:hover {
			@include breakpoint('desktop') {
				.downloads_item-link-text {
					border-color: white;
				}
			}
		}
	}
}
